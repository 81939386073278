import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import moment from 'moment-timezone';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  DialogContent,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FileDownload from 'js-file-download';
import { TextField } from 'formik-mui';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import BackArrow from '../../components/BackArrow';
import {
  fetchOrder,
  acceptOrder,
  refundOrder,
  cancelOrder,
  fetchOrderReceipt,
  clearOrder,
} from '../../store/orders';
import { getOrderState } from '../../store/orders/selectors';

import Page from '../../components/Page';
import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import withVenue from '../../hoc/withVenue';
import TableLink from '../../components/TableLink';
import useRoles from '../../hooks/useRoles';
import CustomDialog from '../../components/CustomDialog';
import PageHeader from '../../components/PageHeader';
import EmailReceiptDialog from './EmailReceiptDialog';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: '15px 0 30px',
  },
  card: {
    marginBottom: '20px',
  },
  button: {
    marginRight: theme.spacing(),
  },
  refundForm: {
    justifyContent: 'flex-end',
  },
  bold: {
    fontWeight: 'bold',
  },
  modifier: {
    margin: '0',
  },
}));

const stripePaymentUriPrefix = `https://dashboard.stripe.com/${
  process.env.REACT_APP_STAGE === 'development' ? 'test/' : ''
}payments/`;
const checkoutPaymentUriPrefix = `https://${
  process.env.REACT_APP_STAGE === 'development' ? 'sandbox' : 'hub'
}.checkout.com/payments/details/act_`;

const setPaymentUri = (paymentService, paymentIntent, checkoutPaymentId) => {
  if (paymentService === 'STRIPE') {
    return `${stripePaymentUriPrefix}${paymentIntent}`;
  }
  if (paymentService === 'CHECKOUT') {
    return `${checkoutPaymentUriPrefix}${checkoutPaymentId?.split('_')[1]}`;
  }
  return null;
};

const Order = ({ redirect }) => {
  const { orderId } = useParams();
  const { tabOrder, state } = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isAdmin, isRoleAtLeastManager } = useRoles();
  const { loading, data: orderState, error } = useSelector(getOrderState);
  const order = tabOrder || orderState;
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isEmailDialogOpen, setIsEmailDialogOpen] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { push } = useHistory();

  const {
    acceptedAt,
    updatedAt,
    orderDisplayId,
    tableName,
    name,
    status,
    paymentMethod,
    paymentService,
    paymentSource,
    paymentIntent,
    checkoutPaymentId,
    notes,
    refundedAmount,
    pickupTime,
    pickupDay,
    systemVenueNotes,
    cart,
    totalDiscount,
    subtotal,
    deliveryFees: { feeForRestaurantProvidedDelivery } = {},
    staffName,
    payServiceCharge,
    payAdditionalCharge,
    additionalChargeSubtotal,
    additionalChargeLabel,
    serviceSubtotal,
    total,
    discounts,
    vouchers,
    orderPayments,
    cardDetails: { brand, exp, last4, card } = {},
    tip,
    isPrepaid,
    isDeposit,
    source,
    isMultiVenue,
  } = order || {};

  const paymentUri = setPaymentUri(paymentService, paymentIntent, checkoutPaymentId);

  const paymentLink =
    isAdmin() && paymentUri ? (
      <Link target="_blank" underline="always" href={paymentUri}>
        {paymentService}
      </Link>
    ) : null;

  const isVoucherOrder = status === 'VOUCHER_ORDER_ACCEPTED';
  const purchasedVouchers =
    isVoucherOrder &&
    cart.reduce((acc, { voucherCode }) => [...voucherCode.split(', '), ...acc], []);

  const hasVouchers = vouchers && vouchers.length > 0;
  const displayDiscounts = discounts?.some((discount) => discount.occurrences > 0);
  const discountsApplied = discounts?.reduce(
    (res, discount) =>
      discount.occurrences > 0
        ? `${res} ${discount.offer} ${discount.promoCode && `(${discount.promoCode})`}`
        : res,
    '',
  );
  // all orders now have order payments, split bill would be more than 1
  const hasSplitBill = orderPayments && orderPayments.length > 1;

  const hasPaymentDetails = orderPayments && orderPayments.length > 0;

  const goToPage = useCallback(
    (to) => {
      push(to || '/');
    },
    [push],
  );

  const handleAccept = async () => {
    try {
      await dispatch(acceptOrder(orderId));

      showSuccessNotification('Order has been accepted successfully');
      goToPage('/orders-live');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handleCancel = async () => {
    try {
      await dispatch(cancelOrder(orderId));
      showSuccessNotification('Order has been cancelled successfully');
      goToPage(status === 'USER_PAID' ? '/orders-live' : '/orders-history');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handleRefund = async () => {
    try {
      await dispatch(refundOrder(orderId));

      showSuccessNotification('Order has been refunded successfully');
      goToPage('/orders-history');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handlePartialRefund = async (values) => {
    try {
      await dispatch(refundOrder(orderId, values.partialAmount));

      showSuccessNotification('Order has been refunded successfully');
      goToPage('/orders-history');
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const RefundSchema = Yup.object().shape({
    full: Yup.boolean(),
    partialAmount: Yup.number().when('full', {
      is: false,
      then: Yup.number().min(0.01).max(total),
    }),
  });

  const handlePrintReceipt = async () => {
    const result = await dispatch(fetchOrderReceipt(orderId));
    FileDownload(result, `VAT Receipt - ${orderDisplayId}.pdf`);
  };

  const acceptable = status === 'USER_PAID' || status === 'TAB_ORDER';
  const cancellable =
    status === 'USER_PAID' || status === 'TAB_ORDER' || status === 'USER_PAID_TAKEAWAY';
  const refundable = status === 'ACCEPTED' && !tabOrder && !hasSplitBill;
  const isPartialRefundable = !hasVouchers;
  const canPrintReceipt = status === 'ACCEPTED';

  useEffect(() => {
    if (!tabOrder) {
      dispatch(clearOrder);
      dispatch(fetchOrder(orderId));
    }
  }, [tabOrder, orderId, dispatch]);

  // Dynamically update the redirect to go back to the parent page it came from.
  // Memoized to ensure referential equality.
  const redirectUrl = useMemo(() => {
    if (state && state.from === '/orders-live') {
      const redirectPath = () => {
        goToPage('/orders-live');
      };
      return redirectPath;
    }
    return redirect;
  }, [goToPage, redirect, state]);

  return (
    <>
      <PageHeader fullWidth>
        <div className={classes.heading}>
          <BackArrow redirect={redirectUrl} text="Orders" />
          <Typography className={classes.heading} variant="h2" component="h1">
            Order: {orderDisplayId}
          </Typography>
          {acceptable && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleAccept}
              className={classes.button}
            >
              Accept order
            </Button>
          )}
          {refundable && (
            <>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!isRoleAtLeastManager()}
                onClick={() => setIsDialogOpen(true)}
                className={classes.button}
              >
                Refund order
              </Button>
            </>
          )}
          {canPrintReceipt && (
            <>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                onClick={() => handlePrintReceipt()}
                className={classes.button}
              >
                Print receipt
              </Button>
            </>
          )}
          {cancellable && (
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={handleCancel}
              className={classes.button}
            >
              Cancel order
            </Button>
          )}
          {isMultiVenue && (
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={() => setIsEmailDialogOpen(true)}
              className={classes.button}
            >
              Email receipt
            </Button>
          )}
        </div>
      </PageHeader>
      <Page loading={loading} error={tabOrder ? null : error} fullWidth>
        {order && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TableContainer component={Card} className={classes.card}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Name:</TableCell>
                        <TableCell align="right">{name}</TableCell>
                      </TableRow>
                      {staffName && (
                        <TableRow>
                          <TableCell>Staff name:</TableCell>
                          <TableCell align="right">{staffName}</TableCell>
                        </TableRow>
                      )}
                      {paymentService && (
                        <TableRow>
                          <TableCell>Payment Service:</TableCell>
                          <TableCell align="right">{paymentLink || paymentService}</TableCell>
                        </TableRow>
                      )}
                      {paymentMethod && (
                        <TableRow>
                          <TableCell>Payment Method:</TableCell>
                          <TableCell align="right">{paymentMethod}</TableCell>
                        </TableRow>
                      )}
                      {paymentSource && (
                        <TableRow>
                          <TableCell>Payment Source:</TableCell>
                          <TableCell align="right">{paymentSource}</TableCell>
                        </TableRow>
                      )}
                      {brand && (
                        <TableRow>
                          <TableCell>Card Type:</TableCell>
                          <TableCell align="right">{brand}</TableCell>
                        </TableRow>
                      )}
                      {card && card !== 'missing' && (
                        <TableRow>
                          <TableCell>Card:</TableCell>
                          <TableCell align="right">{card}</TableCell>
                        </TableRow>
                      )}
                      {last4 && (
                        <TableRow>
                          <TableCell>Card Ending:</TableCell>
                          <TableCell align="right">{last4}</TableCell>
                        </TableRow>
                      )}
                      {exp && (
                        <TableRow>
                          <TableCell>Expiry:</TableCell>
                          <TableCell align="right">{exp}</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell>Status:</TableCell>
                        <TableCell align="right">{status}</TableCell>
                      </TableRow>
                      {refundedAmount !== undefined && (
                        <TableRow>
                          <TableCell>Amount refunded:</TableCell>
                          <TableCell align="right">{`£${refundedAmount.toFixed(2)}`}</TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TableContainer component={Card} className={classes.card}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell>Date:</TableCell>
                        <TableCell align="right">
                          {moment(acceptedAt || updatedAt)
                            .local()
                            .format('DD MMM yyyy')}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Time:</TableCell>
                        <TableCell align="right">
                          {moment(acceptedAt || updatedAt)
                            .local()
                            .local()
                            .format('HH:mm')}
                        </TableCell>
                      </TableRow>
                      {isVoucherOrder ? (
                        <TableRow>
                          <TableCell>Vouchers:</TableCell>
                          <TableCell align="right">
                            {purchasedVouchers.map((code) => (
                              <TableLink key={code} to={`/voucher/${code}`}>
                                {code}
                              </TableLink>
                            ))}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell>Table:</TableCell>
                          <TableCell align="right">{tableName}</TableCell>
                        </TableRow>
                      )}
                      {isVoucherOrder && isPrepaid !== undefined && (
                        <TableRow>
                          <TableCell>Prepaid:</TableCell>
                          <TableCell align="right">{isPrepaid === true ? 'YES' : 'NO'}</TableCell>
                        </TableRow>
                      )}
                      {isVoucherOrder && isDeposit !== undefined && (
                        <TableRow>
                          <TableCell>Deposit:</TableCell>
                          <TableCell align="right">{isDeposit === true ? 'YES' : 'NO'}</TableCell>
                        </TableRow>
                      )}
                      {notes && (
                        <TableRow>
                          <TableCell>Notes:</TableCell>
                          <TableCell align="right">{notes}</TableCell>
                        </TableRow>
                      )}
                      {source && (
                        <TableRow>
                          <TableCell>Source:</TableCell>
                          <TableCell align="right">{source}</TableCell>
                        </TableRow>
                      )}
                      {pickupTime && (
                        <TableRow>
                          <TableCell>Pickup Time:</TableCell>
                          <TableCell align="right">{pickupTime}</TableCell>
                        </TableRow>
                      )}
                      {pickupDay && (
                        <TableRow>
                          <TableCell>Pickup Day:</TableCell>
                          <TableCell align="right">
                            {`${pickupDay} (${moment(pickupDay, 'YYYY-MM-DD')
                              .local()
                              ?.format('dddd')})`}
                          </TableCell>
                        </TableRow>
                      )}
                      {displayDiscounts && (
                        <>
                          <TableRow>
                            <TableCell>Promotions</TableCell>
                            <TableCell align="right">{discountsApplied}</TableCell>
                          </TableRow>
                          {!!totalDiscount && (
                            <TableRow>
                              <TableCell>Promotion discount</TableCell>
                              <TableCell align="right">{`£${totalDiscount.toFixed(2)}`}</TableCell>
                            </TableRow>
                          )}
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            {systemVenueNotes && (
              <TableContainer component={Card} className={classes.card}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ width: '50px' }}>
                        System Venue Notes:
                      </TableCell>
                      <TableCell align="right" style={{ width: '50px' }}>
                        {systemVenueNotes || 'NONE'}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </TableContainer>
            )}
            {!!cart?.length && (
              <TableContainer component={Card} className={classes.card}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="right" style={{ width: '50px' }}>
                        <Typography variant="h3">Quantity</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h3">Details</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h3">Unit Price</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography variant="h3">Price</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {cart.map(
                      ({
                        uniqItemId,
                        quantity,
                        itemId,
                        itemVariantName,
                        itemName,
                        itemOption,
                        modifiers,
                        unitPrice: cartUnitPrice,
                        discountedPrice,
                        discountedUnitPrice,
                        addonsPrice,
                        price,
                        adhoc,
                      }) => {
                        let unitPrice = cartUnitPrice;
                        if (
                          discountedUnitPrice !== undefined &&
                          discountedUnitPrice !== unitPrice
                        ) {
                          unitPrice = discountedUnitPrice;
                        } else if (discountedPrice !== undefined && discountedPrice !== price) {
                          unitPrice = (discountedPrice - addonsPrice) / quantity;
                        }

                        return (
                          <TableRow key={`${itemId}${uniqItemId}`}>
                            <TableCell align="right">{quantity}</TableCell>
                            <TableCell>
                              {!adhoc ? (
                                <TableLink to={`/menu-items/${itemId}`}>{itemName}</TableLink>
                              ) : (
                                itemName
                              )}
                              <br />
                              {itemOption}
                              {!!itemVariantName && ` (${itemVariantName})`}
                              {modifiers &&
                                modifiers.map((mod) => (
                                  <Typography
                                    key={`${itemId}${mod.modifierId}`}
                                    variant="subtitle1"
                                  >
                                    {mod.modifierItems.map((modItem) => (
                                      <p
                                        key={`${itemId}${modItem.itemId}`}
                                        className={classes.modifier}
                                      >
                                        {modItem.itemName}
                                        {modItem?.itemPrice > 0 &&
                                          ` (£${modItem.itemPrice.toFixed(2)}) `}
                                        {modItem.quantity > 1 && ` x${modItem.quantity} `}
                                      </p>
                                    ))}
                                  </Typography>
                                ))}
                            </TableCell>
                            <TableCell align="right">£{unitPrice.toFixed(2)}</TableCell>
                            <TableCell align="right">
                              £
                              {discountedPrice !== undefined && discountedPrice !== price
                                ? discountedPrice.toFixed(2)
                                : price.toFixed(2)}
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )}
                    {subtotal !== total && (
                      <>
                        <TableRow>
                          <TableCell align="right" colSpan="3">
                            Subtotal:
                          </TableCell>
                          <TableCell align="right">£{subtotal.toFixed(2)}</TableCell>
                        </TableRow>
                      </>
                    )}
                    {totalDiscount > 0 && (
                      <>
                        <TableRow>
                          <TableCell align="right" colSpan="3">
                            Total Discount:
                          </TableCell>
                          <TableCell align="right">£-{totalDiscount.toFixed(2)}</TableCell>
                        </TableRow>
                      </>
                    )}
                    {feeForRestaurantProvidedDelivery && feeForRestaurantProvidedDelivery > 0 ? (
                      <>
                        <TableRow>
                          <TableCell align="right" colSpan="3">
                            Restaurant Delivery Fee:
                          </TableCell>
                          <TableCell align="right">
                            £{feeForRestaurantProvidedDelivery.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </>
                    ) : null}
                    {payServiceCharge === true && (
                      <>
                        <TableRow>
                          <TableCell align="right" colSpan="3">
                            Service Charge:
                          </TableCell>
                          <TableCell align="right">£{serviceSubtotal.toFixed(2)}</TableCell>
                        </TableRow>
                      </>
                    )}
                    {tip > 0 && (
                      <>
                        <TableRow>
                          <TableCell align="right" colSpan="3">
                            Tip:
                          </TableCell>
                          <TableCell align="right">£{tip.toFixed(2)}</TableCell>
                        </TableRow>
                      </>
                    )}
                    {payAdditionalCharge === true && (
                      <>
                        <TableRow>
                          <TableCell align="right" colSpan="3">
                            {additionalChargeLabel}:
                          </TableCell>
                          <TableCell align="right">
                            £{additionalChargeSubtotal.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                    {hasVouchers &&
                      vouchers.map(({ voucherCode, amountUsed }) => (
                        <TableRow key={voucherCode}>
                          <TableCell align="right" colSpan="3">
                            Voucher ({voucherCode}):
                          </TableCell>
                          <TableCell align="right">£{amountUsed.toFixed(2)}</TableCell>
                        </TableRow>
                      ))}
                    <TableRow>
                      <TableCell align="right" colSpan="3">
                        Total:
                      </TableCell>
                      <TableCell align="right">£{total.toFixed(2)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {hasPaymentDetails && (
              <>
                <Typography variant="h4">Payments Details</Typography>
                <br />
                <TableContainer component={Card} className={classes.card}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h3">Payment Id</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h3">Status</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="h3">Service</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h3">Time</Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="h3">Amount</Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orderPayments.map(
                        ({
                          status: paymentStatus,
                          amount,
                          updatedAt: paymentUpdatedAt,
                          service,
                          paymentId,
                        }) => {
                          const orderPayemntUri = setPaymentUri(service, paymentId, null);
                          const orderPaymentLink =
                            isAdmin() && orderPayemntUri ? (
                              <Link target="_blank" underline="always" href={orderPayemntUri}>
                                {service}
                              </Link>
                            ) : null;
                          return (
                            <TableRow key={`${paymentId}`}>
                              <TableCell>{paymentId}</TableCell>
                              <TableCell>{paymentStatus}</TableCell>
                              <TableCell>{orderPaymentLink || service}</TableCell>
                              <TableCell align="right">
                                {moment(paymentUpdatedAt).local()?.format('DD MMM YYYY HH:mm')}
                              </TableCell>
                              <TableCell align="right">{amount}</TableCell>
                            </TableRow>
                          );
                        },
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </>
        )}
        <CustomDialog
          title="Choose Refund Amount"
          isDialogOpen={isDialogOpen}
          handleCloseDialog={handleCloseDialog}
        >
          <DialogContent dividers className={classes.refundForm}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={handleRefund}
                >
                  FULL REFUND
                </Button>
              </Grid>
              {isPartialRefundable && (
                <>
                  <Formik
                    initialValues={{ partialAmount: 0, full: false }}
                    onSubmit={handlePartialRefund}
                    validationSchema={RefundSchema}
                  >
                    <Form>
                      <Grid item>
                        <Field
                          component={TextField}
                          type="number"
                          fullWidth
                          inputProps={{ step: '0.01' }}
                          label="Partial amount (£)"
                          name="partialAmount"
                          variant="outlined"
                          margin="normal"
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={classes.button}
                        >
                          PARTIAL REFUND
                        </Button>
                      </Grid>
                    </Form>
                  </Formik>
                </>
              )}
            </Grid>
          </DialogContent>
        </CustomDialog>
        <EmailReceiptDialog
          open={isEmailDialogOpen}
          onClose={() => setIsEmailDialogOpen(false)}
          orderId={orderId}
          orderDisplayId={orderDisplayId}
        />
      </Page>
    </>
  );
};

export default withVenue(Order, '/orders-history', clearOrder);
