import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Card,
  CardContent,
  FormGroup,
  MenuItem,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik, Form, Field, useFormikContext } from 'formik';
import { Switch, TextField } from 'formik-mui';
import { nanoid } from 'nanoid';
import * as Yup from 'yup';

import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';
import { updatePrinter } from '../../store/printers';
import useRoles from '../../hooks/useRoles';
import UniversalSave from '../UniversalSave';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
  card: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const FormObserver = ({ setIsDirty }) => {
  const { dirty } = useFormikContext();
  useEffect(() => {
    setIsDirty(dirty);
  }, [dirty, setIsDirty]);
  return null;
};

const EditPrinterForm = ({
  printerData: { printerId, name, destination, active, online, model, autocut, allowDoublePrint },
  printerData,
  setIsDirty,
}) => {
  const destinationTypes = ['DRINKS', 'FOOD', 'ALL'];
  const autocutTypes = ['FULL', 'PARTIAL'];
  const dispatch = useDispatch();
  const classes = useStyles();
  const { isAdmin } = useRoles();
  const { showErrorNotification, showSuccessNotification } = useNotifications();

  const initialValues = { printerId, name, destination, autocut, allowDoublePrint };

  const onSubmit = async (updatedPrinter) => {
    const transformedUpdatedPrinter = {
      ...updatedPrinter,
    };
    try {
      await dispatch(updatePrinter(transformedUpdatedPrinter));

      showSuccessNotification('Printer has been updated successfully');
    } catch (localError) {
      showErrorNotification(getErrorMessage(localError));
    }
  };

  const EditPrinterSchema = Yup.object().shape({
    printerId: Yup.string().oneOf([printerId]).required(),
    name: Yup.string().optional(),
    destination: Yup.string().oneOf(destinationTypes).required('Please choose a valid destination'),
  });

  return (
    <>
      <Card>
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={EditPrinterSchema}
          initialValues={initialValues}
        >
          {({ setFieldValue, dirty, isValid, values, resetForm, errors }) => (
            <>
              <UniversalSave
                isValid={isValid}
                errors={errors}
                dirty={dirty}
                onSave={() => onSubmit(values)}
                onDiscard={resetForm}
              />
              <Form>
                <FormObserver setIsDirty={setIsDirty} />
                <CardContent>
                  <section>
                    <Field
                      component={TextField}
                      name="printerId"
                      label="Printer Id"
                      variant="outlined"
                      fullWidth
                      required
                      disabled
                      margin="normal"
                    />
                    <Field
                      component={TextField}
                      name="name"
                      label="Name"
                      variant="outlined"
                      fullWidth
                      required
                      margin="normal"
                    />
                  </section>
                  <section>
                    <FormGroup className={classes.section}>
                      <Field
                        component={TextField}
                        select
                        fullWidth
                        name="destination"
                        label="Select Destination"
                        variant="outlined"
                        margin="normal"
                        required
                        onChange={({ target }) => {
                          setFieldValue('destination', target.value);
                        }}
                      >
                        {destinationTypes.map((destinationType) => {
                          const key = nanoid();

                          return (
                            <MenuItem value={destinationType} key={key}>
                              {destinationType}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormGroup>
                  </section>
                  <section>
                    <FormGroup className={classes.section}>
                      <Field
                        component={TextField}
                        select
                        fullWidth
                        name="autocut"
                        label="Autocut"
                        variant="outlined"
                        margin="normal"
                        required
                        onChange={({ target }) => {
                          setFieldValue('autocut', target.value);
                        }}
                      >
                        {autocutTypes.map((autocutType) => {
                          const key = nanoid();

                          return (
                            <MenuItem value={autocutType} key={key}>
                              {autocutType}
                            </MenuItem>
                          );
                        })}
                      </Field>
                    </FormGroup>
                  </section>
                  <section>
                    <FormControlLabel
                      label="Double print ticket"
                      labelPlacement="start"
                      control={
                        <Field
                          component={Switch}
                          color="primary"
                          type="checkbox"
                          name="allowDoublePrint"
                          id="ingredient_isAlcoholic"
                        />
                      }
                    />
                  </section>
                </CardContent>
              </Form>
            </>
          )}
        </Formik>
      </Card>

      {isAdmin() && printerData && (
        <>
          <Typography className={classes.heading} variant="h4" component="h4">
            Printer Info
          </Typography>
          <TableContainer component={Card} className={classes.card}>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>Active:</TableCell>
                  <TableCell align="right">{active.toString()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Online:</TableCell>
                  <TableCell align="right">{online?.toString()}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Model:</TableCell>
                  <TableCell align="right">{model}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
};

export default EditPrinterForm;
