import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

import { getErrorMessage } from '../../shared/utils/errors';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { fetchPrinters, updatePrinter } from '../../store/printers';
import withVenue from '../../hoc/withVenue';
import PrinterForm from '../../components/PrinterForm';
import BackArrow from '../../components/BackArrow';

const useStyles = makeStyles(() => ({
  heading: {
    margin: '15px 0',
  },
}));

const AddPrinter = ({ redirect }) => {
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPrinters());
  }, [dispatch]);

  const handleOnSubmit = async (values) => {
    try {
      await dispatch(updatePrinter(values));
      showSuccessNotification('Printer has been added successfully');
      redirect();
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  const classes = useStyles();

  return (
    <>
      <header>
        <BackArrow redirect={redirect} text="Devices" />
        <Typography className={classes.heading} variant="h2" component="h1">
          Add Printer
        </Typography>
      </header>
      <PrinterForm
        formAction="update"
        initialValues={{
          printerId: '',
          destination: '',
          autocut: 'FULL',
          allowDoublePrint: false,
        }}
        onSubmit={handleOnSubmit}
      />
    </>
  );
};

export default withVenue(AddPrinter, '/devices');
