import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clone, pick } from 'lodash';
import * as Yup from 'yup';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  IconButton,
  TextField,
  Typography,
  Grid,
  Tooltip,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

import { useHistory, useParams } from 'react-router';
import { MdDelete, MdAdd } from 'react-icons/md';
import { LoyaltyOutlined } from '@mui/icons-material';
import copy from 'clipboard-copy';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import useEndpoint from '../../../hooks/useEndpoint';
import BrandsModifierForm from '../../../components/Brands/BrandsModifierForm';
import { getBrandsState } from '../../../store/brands/brands/selectors';
import {
  clearBrandsModifiers,
  createBrandsModifier,
  fetchBrandsModifiers,
  updateBrandsModifier,
  deleteBrandsModifier,
} from '../../../store/brands/brandsModifiers';

import Page from '../../../components/Page';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import withVenue from '../../../hoc/withVenue';
import CustomDialog from '../../../components/CustomDialog';
import OrderableTable from '../../../components/OrderableTable';
import TableButton from '../../../components/TableButton';
import useRoles from '../../../hooks/useRoles';
import PageHeader from '../../../components/PageHeader';
import { getBrandsModifiersState } from '../../../store/brands/brandsModifiers/selectors';
import { getBrandsMenuItemsState } from '../../../store/brands/brandsMenuItems/selectors';
import { fetchBrandsMenuItems } from '../../../store/brands/brandsMenuItems';
import shouldLoad from '../../../shared/utils/shouldLoad';
import { fetchBrands } from '../../../store/brands/brands';
import { filterTableData } from '../../../shared/utils/filterData';
import useSearch from '../../../hooks/useSearch';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
  },
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  title: {
    flexGrow: 1,
  },
  deleteModifierWarning: {
    ...theme.customFonts.medium,
    color: theme.customPalette.greyDarker,
  },
  typeDelete: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
    marginTop: '16px',
  },
  listItemsAssociated: {
    display: 'flex',
    flexDirection: 'column',
  },
  itemsAssociatedLink: {
    ...theme.customFonts.small,
    color: theme.customPalette.trueBlue,
  },
  deleteCopy: {
    ...theme.customFonts.small,
    color: '#D2222D',
  },
  tableControlsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `${theme.spacing(2)} 0`,
  },
}));

const DeleteModifierValidationSchema = Yup.object().shape({
  delete: Yup.string()
    .required('You must type the word ‘delete’ to delete this modifier.')
    .test('is-delete', 'This doesn’t look right, please check the spelling', (value) => {
      const deleteRegex = /^delete$/i; // The i flag makes the regex case-insensitive
      return deleteRegex.test(value);
    }),
});

const BrandsModifiers = () => {
  const localStorageBrandsFilter = JSON.parse(localStorage.getItem('brandsFilter'));
  const [brandsFilter, setBrandsFilter] = useState(localStorageBrandsFilter || []);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { itemId: modifierParamId } = useParams() || {};
  const { loading, data, error } =
    useEndpoint(getBrandsModifiersState, fetchBrandsModifiers()) || {};
  const { data: menuItems } = useEndpoint(getBrandsMenuItemsState, fetchBrandsMenuItems()) || {};
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const brandsState = useSelector(getBrandsState);
  const { data: brands } = brandsState;
  const [modifierData, setModifierData] = useState(null);
  const [formAction, setFormAction] = useState('');
  const [modifierToBeDeleted, setModifierToBeDeleted] = useState(null);
  const [itemsAssociatedToModifer, setItemsAssociatedToModifer] = useState([]);
  const [isDeleteModifierDialogOpen, setIsDeleteModifierDialogOpen] = useState(false);
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const { isRoleAtLeastManager } = useRoles();
  const searchKeys = useMemo(() => ['modifierName', 'label'], []);
  const threshold = 0.2;

  const { searchResults, searchError, handleSearch, filteredItems, setFilteredItems } = useSearch(
    data,
    searchKeys,
    threshold,
    null,
  );

  const handleCloseDeleteModifierDialog = (resetForm) => {
    setIsDeleteModifierDialogOpen(false);
    resetForm();
  };

  const handleOpenDeleteModifierDialog = (modifier) => {
    setModifierToBeDeleted(modifier);
    setIsDeleteModifierDialogOpen(true);
  };

  const handleOnSubmit = async (values) => {
    const submitValues = clone(values);
    submitValues.modifierItems = submitValues.modifierItems.map((item) => ({
      itemId: item.itemId,
      itemPrice: item.itemPrice,
      multiMax: item.multiMax,
    }));
    if (formAction === 'create') {
      try {
        await dispatch(createBrandsModifier(submitValues));
        showSuccessNotification('Modifier has been added successfully');
        setIsDialogOpen(false);
        dispatch(fetchBrandsModifiers());
      } catch (e) {
        showErrorNotification(getErrorMessage(e));
        setIsDialogOpen(false);
      }
    }

    if (formAction === 'update') {
      try {
        const { modifierId } = modifierData;
        await dispatch(updateBrandsModifier(modifierId, submitValues));
        if (modifierParamId) {
          history.goBack();
          dispatch(fetchBrandsModifiers());
          showSuccessNotification('Modifier has been updated successfully');
          return;
        }
        showSuccessNotification('Modifier has been updated successfully');
        setIsDialogOpen(false);
        dispatch(fetchBrandsModifiers());
      } catch (newError) {
        showErrorNotification(getErrorMessage(newError));
      }
    }
  };

  const handleEdit = useCallback(
    (modifier) => {
      // When calling this function, a check is first made to see if the modifier is an id, not an object
      if (modifierParamId && data) {
        const modToUpdate = data.find((obj) => obj.modifierId === modifierParamId);
        setFormAction('update');
        setModifierData(modToUpdate);
        setIsDialogOpen(true);
        return;
      }
      setFormAction('update');
      setModifierData(modifier);
      setIsDialogOpen(true);
    },
    [data, modifierParamId],
  );

  // BE does not support deleting modifiers. This will be done in future.

  const handleDelete = async (modId) => {
    try {
      await dispatch(deleteBrandsModifier(modId));
      setIsDeleteModifierDialogOpen(false);
      showSuccessNotification('Modifier has been deleted successfully');
    } catch (err) {
      await dispatch(fetchBrandsModifiers());
      showErrorNotification(getErrorMessage(err));
    }
  };

  const handleOpenDialog = () => {
    setModifierData(null);
    setFormAction('create');
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    if (modifierParamId) {
      history.goBack();
    }
  };

  const checkMenuItemsModifierIsAppliedTo = useCallback(
    (modifierId) => {
      if (data) {
        const modifiers = menuItems?.filter((item) => item.modifiers?.includes(modifierId));
        if (modifiers) {
          setItemsAssociatedToModifer(modifiers);
          return modifiers;
        }
      }
      return 0;
    },
    [data, menuItems],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (filteredItems) {
      filteredItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          delete: 'delete',
          appliedToItems: menuItems?.filter((menuItem) =>
            menuItem.modifiers?.includes(item.modifierId) ? menuItem : 0,
          ),
          modifierItems: item.modifierItems?.map((modifierItem) => ({
            ...modifierItem,
            multiMax: modifierItem.multiMax || 1,
          })),
        };

        pickedData.push(
          pick(item, [
            'modifierName',
            'label',
            'brandName',
            'modifierItems',
            'appliedToItems',
            'modifierId',
            'maxSelections',
            'minSelections',
            'delete',
            'readonly',
          ]),
        );
      });
    }
    return pickedData;
  }, [filteredItems, menuItems]);

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'modifierName':
          return row.readonly ? (
            <p>
              {value} <Chip label="Read-only" />
            </p>
          ) : (
            <TableButton
              onClick={() => {
                handleEdit(row);
              }}
            >
              {row.modifierName}
            </TableButton>
          );
        case 'modifierItems':
          return row.modifierItems.length;
        case 'appliedToItems':
          return row.appliedToItems?.length;
        case 'delete':
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Copy modifier ID" disableInteractive>
                <IconButton
                  size="small"
                  onClick={() => {
                    copy(row.modifierId);
                  }}
                  sx={{ margin: '0 5px' }}
                >
                  <LoyaltyOutlined sx={{ width: 18, height: 18 }} />
                </IconButton>
              </Tooltip>
              <IconButton
                edge="end"
                size="small"
                onClick={() => {
                  handleOpenDeleteModifierDialog(row);
                  checkMenuItemsModifierIsAppliedTo(row.modifierId);
                }}
                disabled={row?.readonly}
              >
                <MdDelete />
              </IconButton>
            </Box>
          );
        default:
          return value;
      }
    },
    [checkMenuItemsModifierIsAppliedTo, handleEdit],
  );

  const dialogTitle = useMemo(() => {
    if (formAction === 'update') {
      return 'Update modifier';
    }
    return 'Create new modifier';
  }, [formAction]);

  // To check if param for modifier id is present in URL
  useEffect(() => {
    if (modifierParamId) {
      handleEdit(modifierParamId);
    }
  }, [handleEdit, modifierParamId]);

  useEffect(() => {
    setFilteredItems(filterTableData(brandsFilter, searchResults || data));
    if (shouldLoad(brandsState)) dispatch(fetchBrands());
  }, [brandsFilter, brandsState, data, dispatch, searchResults, setFilteredItems]);

  const handleBrandsFilter = (_e, value) => {
    setBrandsFilter(value);
    localStorage.setItem('brandsFilter', JSON.stringify(value));
    setFilteredItems(filterTableData(value, searchResults));
  };

  return (
    <>
      <Box className={classes.heading}>
        <aside>
          <CustomDialog
            isDialogOpen={isDialogOpen}
            handleCloseDialog={handleCloseDialog}
            title={dialogTitle}
          >
            <BrandsModifierForm
              formAction={formAction}
              modifierData={modifierData}
              onSubmit={handleOnSubmit}
              onCancel={handleCloseDialog}
            />
          </CustomDialog>
        </aside>
      </Box>
      <PageHeader fullWidth>
        <Typography paragraph>
          Modifiers are groups of choices that can be applied to a menu item such as mixers, or a
          choice of salad options
        </Typography>
        <Grid container className={classes.tableControlsBox} direction="row">
          <Grid item container spacing={2} sm={12} md={9} direction="row">
            <Grid item md={4} sm={6} lg={4} xl={3}>
              <TextField
                fullWidth
                className={classes.searchInput}
                label="Search Modifiers"
                variant="outlined"
                helperText={searchError}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item md={8} sm={6} lg={7} xl={4}>
              {brands && brandsFilter && (
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={2}
                  id="brands-autocomplete"
                  options={brands}
                  getOptionLabel={(option) => option.name}
                  defaultValue={
                    brandsFilter.length > 0
                      ? brands.filter((brand) =>
                          brandsFilter.some((filter) => filter.brandId === brand.brandId),
                        )
                      : brands
                  }
                  onChange={handleBrandsFilter}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Brands" />
                  )}
                />
              )}
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            disabled={!isRoleAtLeastManager()}
            onClick={handleOpenDialog}
            startIcon={<MdAdd />}
          >
            Create Modifier
          </Button>
        </Grid>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {filteredItems && filteredItems.length === 0 && (
          <Box style={{ padding: 14 }}>
            <Typography align="center" variant="h2" color="textSecondary">
              No modifiers found
            </Typography>
          </Box>
        )}
        {data && (
          <>
            {data && (
              <Card>
                <>
                  <OrderableTable
                    tableData={[...newData()]}
                    titles={[
                      'INTERNAL NAME',
                      'EXTERNAL LABEL',
                      'BRAND',
                      'OPTION COUNT',
                      'APPLIED TO ITEMS',
                      '',
                    ]}
                    excludeFields={['modifierId', 'minSelections', 'maxSelections']}
                    keys={['modifierId']}
                    disableColumnTitles={['']}
                    valueFormatter={valueFormatter}
                  />
                </>
              </Card>
            )}
          </>
        )}
        <Formik
          initialValues={{ delete: '' }}
          validationSchema={itemsAssociatedToModifer?.length > 0 && DeleteModifierValidationSchema}
          onSubmit={() => handleDelete(modifierToBeDeleted.modifierId)}
        >
          {({ values, setFieldValue, submitForm, resetForm, setFieldError }) => (
            <Form>
              <CustomDialog
                isDialogOpen={isDeleteModifierDialogOpen}
                handleCloseDialog={() => handleCloseDeleteModifierDialog(resetForm)}
                title="Delete this modifier?"
                actions={
                  <>
                    <Button
                      variant="outlined"
                      onClick={() => handleCloseDeleteModifierDialog(resetForm)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{ background: '#D2222D', color: '#fff' }}
                      type="submit"
                      onClick={() => submitForm(values, resetForm)}
                    >
                      Delete modifier
                    </Button>
                  </>
                }
              >
                {itemsAssociatedToModifer?.length > 0 && (
                  <CardContent>
                    <Typography className={classes.deleteModifierWarning}>
                      Permanently deleting &apos;{modifierToBeDeleted?.modifierName}&apos; modifier
                      will remove it from the following items:
                    </Typography>
                    <Box className={classes.listItemsAssociated}>
                      {itemsAssociatedToModifer.map((item) => (
                        <Link
                          className={classes.itemsAssociatedLink}
                          key={item.itemId}
                          to={`/brand-menu-management/items/${item.itemId}`}
                          target="_blank"
                        >
                          {item.itemName}
                        </Link>
                      ))}
                    </Box>
                    <Typography className={classes.typeDelete}>
                      Type <b>delete</b> to confirm
                    </Typography>
                    <Field
                      component={TextField}
                      size="small"
                      fullWidth
                      name="delete"
                      variant="outlined"
                      required
                      onChange={(e) => {
                        setFieldValue('delete', e.target.value, false);
                        setFieldError('delete', '', false);
                      }}
                    />
                    <ErrorMessage name="delete" component="div" className={classes.deleteCopy} />
                  </CardContent>
                )}
              </CustomDialog>
            </Form>
          )}
        </Formik>
      </Page>
    </>
  );
};

export default withVenue(
  BrandsModifiers,
  '/brand-menu-management/items/modifiers',
  clearBrandsModifiers,
);
