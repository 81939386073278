import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';

import { makeStyles } from '@mui/styles';
import {
  Button,
  Card,
  Chip,
  IconButton,
  TextField,
  Grid,
  Autocomplete,
  Box,
  Typography,
  Tooltip,
} from '@mui/material';
import { MdDelete, MdAdd } from 'react-icons/md';
import { LoyaltyOutlined } from '@mui/icons-material';
import copy from 'clipboard-copy';

import { pick } from 'lodash';
import TableLink from '../../../components/TableLink';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../../shared/utils/errors';
import { isDeliveryOnly } from '../../../store/venues/selectors';
import { deleteBrandsMenuItem, fetchBrandsMenuItems } from '../../../store/brands/brandsMenuItems';

import Page from '../../../components/Page';
import withVenue from '../../../hoc/withVenue';
import shouldLoad from '../../../shared/utils/shouldLoad';
import OrderableTable from '../../../components/OrderableTable';
import UniversalSave from '../../../components/UniversalSave';
import useRoles from '../../../hooks/useRoles';
import PageHeader from '../../../components/PageHeader';
import { getBrandsState } from '../../../store/brands/brands/selectors';
import { fetchBrands } from '../../../store/brands/brands';
import { getBrandsMenuItemsState } from '../../../store/brands/brandsMenuItems/selectors';
import { filterTableData } from '../../../shared/utils/filterData';
import useSearch from '../../../hooks/useSearch';

const useStyles = makeStyles((theme) => ({
  tableControlsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: `${theme.spacing(2)} 0`,
  },
  title: {
    flexGrow: 1,
  },
  box: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginBottom: '10px',
  },
}));

const FormObserver = ({ setFieldValue, setValueData }) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setValueData(values);
  }, [values, dirty, setFieldValue, setValueData]);
  return null;
};

const findArrayIndex = (valueData, row) => {
  const index = valueData.findIndex((obj) => obj.itemId === `${row.itemId}`);
  return index;
};

const BrandsMenuItems = () => {
  const localStorageBrandsFilter = JSON.parse(localStorage.getItem('brandsFilter'));
  const [brandsFilter, setBrandsFilter] = useState(localStorageBrandsFilter || []);
  const classes = useStyles();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const dispatch = useDispatch();
  const brandsMenuItemsState = useSelector(getBrandsMenuItemsState);
  const { loading, data, error } = brandsMenuItemsState;
  const brandsState = useSelector(getBrandsState);
  const { data: brands } = brandsState;
  const [valueData, setValueData] = useState(data);
  const { isRoleAtLeastManager, isAdmin } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;
  const searchKeys = useMemo(() => ['itemName', 'label', 'category', 'type'], []);
  const threshold = 0.3;

  const { searchResults, searchError, handleSearch, filteredItems, setFilteredItems } = useSearch(
    data,
    searchKeys,
    threshold,
    valueData,
  );

  const handleDelete = useCallback(
    async (itemId) => {
      try {
        await dispatch(deleteBrandsMenuItem(itemId));
        showSuccessNotification('Item has been deleted successfully');
        dispatch(fetchBrandsMenuItems());
      } catch (err) {
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (filteredItems) {
      filteredItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          delete: 'delete',
        };
        pickedData.push(
          pick(item, [
            'itemName',
            'label',
            'brandName',
            'category',
            'type',
            'itemPrices',
            'itemId',
            'delete',
            'readonly',
          ]),
        );
      });
    }
    return pickedData;
  }, [filteredItems]);

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'itemName':
          return row.readonly ? (
            <p>
              {value} <Chip label="Read-only" />
            </p>
          ) : (
            <TableLink to={`/brand-menu-management/item/${row.itemId}`}>{value}</TableLink>
          );

        case 'delete':
          return (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Tooltip title="Copy item ID" disableInteractive>
                <IconButton
                  size="small"
                  onClick={() => {
                    copy(row.itemId);
                  }}
                  sx={{ margin: '0 5px' }}
                >
                  <LoyaltyOutlined sx={{ width: 18, height: 18 }} />
                </IconButton>
              </Tooltip>
              <IconButton
                edge="end"
                size="small"
                onClick={() => handleDelete(row.itemId)}
                disabled={row?.readonly}
              >
                <MdDelete />
              </IconButton>
            </Box>
          );
        case 'itemPrices':
          return row.itemPrices.map((i) => `£${i}`).join(', ');
        case 'available':
          if (valueData) {
            return (
              <Field
                name={`[${findArrayIndex(valueData, row)}].available`}
                component={Switch}
                checked={valueData[findArrayIndex(valueData, row)]?.available}
                value={!valueData[findArrayIndex(valueData, row)]?.available}
                color="primary"
                type="checkbox"
              />
            );
          }
          return null;
        default:
          return value;
      }
    },
    [handleDelete, valueData],
  );

  const handleBrandsFilter = (_e, value) => {
    setBrandsFilter(value);
    localStorage.setItem('brandsFilter', JSON.stringify(value));
    setFilteredItems(filterTableData(value, searchResults));
  };

  useEffect(() => {
    if (shouldLoad(brandsMenuItemsState)) dispatch(fetchBrandsMenuItems());
    if (shouldLoad(brandsState)) dispatch(fetchBrands());
    setFilteredItems(filterTableData(brandsFilter, searchResults || data));
  }, [
    brandsFilter,
    brandsMenuItemsState,
    brandsState,
    data,
    dispatch,
    searchResults,
    setFilteredItems,
  ]);

  return (
    <>
      <PageHeader fullWidth>
        <Grid container className={classes.tableControlsBox} direction="row">
          <Grid item container spacing={2} sm={12} md={9} direction="row">
            <Grid item md={4} sm={6} lg={4} xl={3}>
              <TextField
                helperText={searchError}
                fullWidth
                id="outlined-basic"
                label="Search Items"
                variant="outlined"
                onChange={handleSearch}
              />
            </Grid>
            <Grid item md={8} sm={6} lg={7} xl={4}>
              {brands && brandsFilter && (
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={2}
                  id="brands-autocomplete"
                  options={brands}
                  getOptionLabel={(option) => option.name}
                  defaultValue={
                    brandsFilter.length > 0
                      ? brands.filter((brand) =>
                          brandsFilter.some((filter) => filter.brandId === brand.brandId),
                        )
                      : brands
                  }
                  onChange={handleBrandsFilter}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Brands" />
                  )}
                />
              )}
            </Grid>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            disabled={!isRoleAtLeastManager() || permissionReadOnly}
            to="/brand-menu-management/items/add"
            startIcon={<MdAdd />}
          >
            Create Item
          </Button>
        </Grid>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {filteredItems && filteredItems.length === 0 && (
          <Box style={{ paddingTop: 16 }}>
            <Typography align="left" variant="h2" color="textSecondary">
              No menu items found
            </Typography>
          </Box>
        )}
        {data && (
          <>
            {filteredItems && (
              <Card>
                <Formik initialValues={[...newData()]}>
                  {({ setFieldValue, values, dirty, resetForm, errors, isValid }) => (
                    <>
                      <UniversalSave
                        isValid={isValid}
                        errors={errors}
                        dirty={dirty}
                        onDiscard={resetForm}
                      />
                      <Form>
                        <FormObserver setFieldValue={setFieldValue} setValueData={setValueData} />
                        <OrderableTable
                          tableData={[...newData()]}
                          titles={[
                            'INTERNAL NAME',
                            'EXTERNAL LABEL',
                            'BRAND',
                            'CATEGORY',
                            'TYPE',
                            'PRICES',
                            '',
                          ]}
                          keys={['itemId']}
                          excludeFields={['itemId']}
                          disableColumnTitles={['PRICES', '']}
                          values={values}
                          valueFormatter={valueFormatter}
                        />
                      </Form>
                    </>
                  )}
                </Formik>
              </Card>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(BrandsMenuItems);
