import { deleteBare, get, getBare, patchBare } from './httpRequest';

export const fetchPrinters = async () => {
  const response = await get(`/printers`);
  return response.data;
};

export const fetchAvailablePrinters = async () => {
  const response = await getBare(`printers`);
  return response.data;
};

export const updatePrinter = async (values) => {
  const venueId = localStorage.getItem('venue_id');
  const { printerId, destination, tables, categories, autocut, name, allowDoublePrint } = values;
  const response = await patchBare(`printers/${printerId}`, {
    data: { destination, tables, categories, autocut, venueId, name, allowDoublePrint },
  });

  return response.data;
};

export const deletePrinter = async (values) => {
  const { printerId } = values;
  await deleteBare(`printers/${printerId}`);
};

export const fetchPrinter = async (values) => {
  const { printerId } = values;
  const response = await get(`/printers/${printerId}`);
  return response.data;
};
