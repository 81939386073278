import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { autoLoginSession } from '../../store/session';

const AutoLogin = () => {
  const dispatch = useDispatch();
  const useQuery = () => new URLSearchParams(useLocation().search);

  const encodedToken = useQuery().get('token');

  useEffect(() => {
    const decodedToken = JSON.parse(decodeURIComponent(atob(encodedToken)));
    dispatch(autoLoginSession(decodedToken));
  }, [dispatch, encodedToken]);

  return null;
};

export default AutoLogin;
