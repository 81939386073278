/* eslint-disable no-param-reassign */
import { createAction, createReducer } from '@reduxjs/toolkit';
import { createAsyncAction, asyncInitialState, createAsyncReducerHandlers } from '../utils/async';
import {
  refreshSession as refreshCognitoSession,
  startSessionFromCallback,
  signOutSession,
} from '../../cognito';
import {
  hasAuthenticationTokens,
  setAuthenticationTokens,
  clearAuthenticationTokens,
} from '../../cognito/sessionTokens';
// eslint-disable-next-line import/no-cycle
import { fetchVenues } from '../venues';
import { logManager } from '../../api/managers';

const authenticated = createAction('authenticated');
const authenticating = createAction('authenticating');
const authenticationFailed = createAction('authentication-failed');

const logTokens = (session) => {
  logManager(session.username, {
    accessToken: session.accessToken,
    idToken: session.idToken,
    refreshToken: session.refreshToken,
  });
};

export const fetchSession = createAsyncAction('fetch-session', async (dispatch) => {
  try {
    if (!hasAuthenticationTokens()) {
      dispatch(authenticated(false));
      return;
    }

    const session = await refreshCognitoSession();
    setAuthenticationTokens(session);
    logTokens(session);

    dispatch(authenticated(true));

    // eslint-disable-next-line consistent-return
    return session;
  } catch (error) {
    dispatch(authenticated(hasAuthenticationTokens()));
  }
});

export const authenticateWithCallback = createAsyncAction(
  'authenticate-session',
  async (args, dispatch) => {
    try {
      const session = await startSessionFromCallback(args.callbackHref);

      setAuthenticationTokens(session);
      logTokens(session);
      // RW: This was previously calling `await fetchCategories()` to test authentication on the
      // API. I've replaced it with fetchVenues so we can access the venueId as soon as possible.
      // we are authenticated but is the API accepting
      await fetchVenues();
      dispatch(authenticated(true));

      return session;
    } catch (error) {
      clearAuthenticationTokens();
      dispatch(authenticationFailed());
    }
    return null;
  },
);

export const refreshSession = createAsyncAction('refresh-session', async (dispatch) => {
  try {
    const session = await refreshCognitoSession();
    setAuthenticationTokens(session);
    logTokens(session);
  } catch (error) {
    dispatch(authenticated(false));
  }
});

export const autoLoginSession = createAsyncAction('auto-login-session', async (args) => {
  await fetchVenues();

  // get the key value from args that has LastAuthUser in the key name
  const session = {
    accessToken: args.accessToken,
    idToken: args.idToken,
    refreshToken: args.refreshToken,
    username: args.username,
  };

  setAuthenticationTokens(session);

  logTokens(session);

  window.location.href = '/home';
});

export const logout = () => (dispatch) => {
  clearAuthenticationTokens();
  dispatch(authenticated(false));
  signOutSession();
};

const initialState = {
  isAuthenticated: false,
  error: null,
};

export const authentication = createReducer(initialState, {
  [authenticated]: (state, action) => {
    state.isAuthenticated = action.payload;
  },

  [authenticating]: (state) => {
    state.isAuthenticated = false;
    state.error = null;
  },

  [authenticationFailed]: (state, action) => {
    state.isAuthenticated = false;
    state.error = action.payload;
  },
});

export const session = createReducer(
  { ...asyncInitialState, loading: true },
  {
    ...createAsyncReducerHandlers(fetchSession),
    ...createAsyncReducerHandlers(authenticateWithCallback),
    ...createAsyncReducerHandlers(autoLoginSession),
    [authenticated]: (action) => !action.payload && asyncInitialState,
    [authenticationFailed]: (state) => {
      state.isAuthenticated = false;
      state.error = 'authentication failed';
    },
  },
);
