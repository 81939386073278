import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { Switch } from 'formik-mui';

import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  IconButton,
  TextField,
  Grid,
  Autocomplete,
  Slider,
  FormControlLabel,
  Checkbox,
  Typography,
  Chip,
  Tooltip,
} from '@mui/material';
import { MdDelete } from 'react-icons/md';
import { LoyaltyOutlined } from '@mui/icons-material';
import copy from 'clipboard-copy';

import { isEqual, pick } from 'lodash';
import TableLink from '../../components/TableLink';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { deleteMenuItem, fetchMenuItems, updateMenuItemsAvailability } from '../../store/menuItems';
import { getErrorMessage } from '../../shared/utils/errors';
import { getMenuItemsState } from '../../store/menuItems/selectors';
import { isDeliveryOnly } from '../../store/venues/selectors';

import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import shouldLoad from '../../shared/utils/shouldLoad';
import OrderableTable from '../../components/OrderableTable';
import UniversalSave from '../../components/UniversalSave';
import useRoles from '../../hooks/useRoles';
import PageHeader from '../../components/PageHeader';
import { filterDataItems } from '../../shared/utils/filterData';
import useSearch from '../../hooks/useSearch';
import isMobile from '../../shared/utils/isMobile';
import isDesktop from '../../shared/utils/isDesktop';

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: '20px',
    display: 'flex',
  },
  buttonClass: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    marginBottom: '10px',
  },
  tableControlsBox: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(2)} 0`,
  },
  title: {
    flexGrow: 1,
  },
  box: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  searchStock: {
    display: 'flex',
    flexDirection: 'flex-row',
    justifyContent: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  searchAndButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
  searchBar: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  categorySliderWrapper: {
    marginTop: '0px',
    [theme.breakpoints.up('lg')]: {
      marginTop: '10px',
    },
  },
  slider: {
    width: '98%',
  },
  button: {
    textWrap: 'nowrap',
    paddingLeft: '10px',
  },
  itemLabelContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  readonlyChip: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: theme.spacing(1),
    },
  },
}));

const FormObserver = ({ setFieldValue, setValueData }) => {
  const { values, dirty } = useFormikContext();
  useEffect(() => {
    setValueData(values);
  }, [values, dirty, setFieldValue, setValueData]);
  return null;
};

const findArrayIndex = (valueData, row) =>
  valueData.findIndex((obj) => obj.itemId === `${row.itemId}`);

const MenuItems = () => {
  const sessionStorageFilters = JSON.parse(sessionStorage.getItem('menuItemsFilter'));
  const classes = useStyles();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const dispatch = useDispatch();
  const menuItemsState = useSelector(getMenuItemsState);
  const { loading, data, error } = menuItemsState;

  const [typeCheckboxState, setTypeCheckboxState] = useState({
    DRINK: true,
    FOOD: true,
  });
  const [availabilityCheckboxState, setAvailabilityCheckboxState] = useState({
    TRUE: true,
    FALSE: true,
  });
  const categoryItems = useMemo(() => [...new Set(data?.map((item) => item.category))], [data]);
  const itemPrices = useMemo(() => {
    if (!data || data.length === 0) return 500000;

    const flatItemPrices = data?.flatMap((items) => items.itemPrices);
    return flatItemPrices.length > 0 ? Math.max(...flatItemPrices) : 500000;
  }, [data]);
  const initialFilters = useMemo(
    () => [
      {
        types: Object.keys(typeCheckboxState),
        categories: categoryItems,
        available: Object.keys(availabilityCheckboxState),
        itemPrices,
      },
    ],
    [typeCheckboxState, categoryItems, availabilityCheckboxState, itemPrices],
  );
  const [filters, setFilters] = useState(sessionStorageFilters || []);
  const [sliderValues, setSliderValues] = useState(itemPrices);
  const [valueData, setValueData] = useState(data);
  const { isRoleAtLeastManager, isAdmin } = useRoles();
  const typeDeliveryOnly = useSelector(isDeliveryOnly);
  const permissionReadOnly = !isAdmin() && typeDeliveryOnly;
  const searchKeys = useMemo(() => ['itemName', 'label', 'category', 'type'], []);
  const threshold = 0.3;

  const { searchResults, searchError, handleSearch, filteredItems, setFilteredItems } = useSearch(
    data,
    searchKeys,
    threshold,
    valueData,
    fetchMenuItems,
  );

  const [initialValues, setInitialValues] = useState([]);

  const handleAvailableChange = async (values) => {
    const changedValues = values.filter((item, index) => !isEqual(item, initialValues[index]));

    try {
      await dispatch(updateMenuItemsAvailability({ values: changedValues }));
      showSuccessNotification('Menu items availability updated successfully');
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  const handleDelete = useCallback(
    async (itemId) => {
      try {
        await dispatch(deleteMenuItem(itemId));
        showSuccessNotification('Item has been deleted successfully');
        dispatch(fetchMenuItems());
      } catch (err) {
        showErrorNotification(getErrorMessage(err));
      }
    },
    [dispatch, showErrorNotification, showSuccessNotification],
  );

  const newData = useCallback(() => {
    const pickedData = [];
    if (filteredItems) {
      filteredItems.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item = {
          ...item,
          label: item.label || item.itemName,
          delete: 'delete',
        };
        pickedData.push(
          pick(item, [
            'itemName',
            'label',
            'category',
            'type',
            'available',
            'itemPrices',
            'itemId',
            'delete',
            'readonly',
          ]),
        );
      });
    }
    return pickedData;
  }, [filteredItems]);

  const valueFormatter = useCallback(
    ({ value, valueName, row }) => {
      switch (valueName) {
        case 'itemName':
          return (
            <Box className={classes.itemLabelContainer}>
              {row.readonly ? (
                <>
                  <Typography sx={{ mr: 1 }} variant="subtitle">
                    {value}
                  </Typography>
                  <Chip label="Read Only" size="small" className={classes.readonlyChip} />
                </>
              ) : (
                <TableLink to={`/menu-items/${row.itemId}`}>
                  <Typography sx={{ mr: 1 }} variant="subtitle">
                    {value}
                  </Typography>
                </TableLink>
              )}
            </Box>
          );
        case 'label':
          return <Typography variant="subtitle">{value || row.itemName}</Typography>;
        case 'delete':
          return (
            <Box sx={isAdmin() ? { display: 'flex', justifyContent: 'flex-end' } : {}}>
              {isAdmin() && (
                <Tooltip title="Copy item ID" disableInteractive>
                  <IconButton
                    size="small"
                    onClick={() => {
                      copy(row.itemId);
                    }}
                    sx={{ margin: '0 5px' }}
                  >
                    <LoyaltyOutlined sx={{ width: 18, height: 18 }} />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton
                edge="end"
                size="small"
                onClick={() => handleDelete(row.itemId)}
                disabled={row?.readonly}
              >
                <MdDelete />
              </IconButton>
            </Box>
          );
        case 'itemPrices':
          return row.itemPrices.map((i) => `£${i}`).join(', ');
        case 'available':
          if (valueData) {
            const index = findArrayIndex(valueData, row);
            const isChecked = valueData[index]?.available || false;
            return (
              <Field
                name={`[${index}].available`}
                component={Switch}
                checked={isChecked}
                value={!isChecked}
                color="primary"
                type="checkbox"
              />
            );
          }
          return null;
        default:
          return value;
      }
    },
    [classes.itemLabelContainer, classes.readonlyChip, handleDelete, isAdmin, valueData],
  );

  const handleFilterChange = (_e, values, filterName) => {
    const { name, checked } = _e.target;
    let filterKeys;

    if (filterName === 'types') {
      const newTypeState = { ...typeCheckboxState, [name]: checked };
      setTypeCheckboxState(newTypeState);
      filterKeys = Object.keys(newTypeState).filter((key) => newTypeState[key]);
    }
    if (filterName === 'available') {
      const newAvailabilityState = { ...availabilityCheckboxState, [name]: checked };
      setAvailabilityCheckboxState(newAvailabilityState);
      filterKeys = Object.keys(newAvailabilityState).filter((key) => newAvailabilityState[key]);
    }

    if (filterName === 'itemPrices') {
      setSliderValues(values);
    }

    const existingFilterData = sessionStorageFilters ? sessionStorageFilters[0] : initialFilters[0];

    const updatedFilter = [
      {
        ...existingFilterData,
        [filterName]: filterName === 'types' || filterName === 'available' ? filterKeys : values,
      },
    ];

    setFilters(updatedFilter);
    sessionStorage.setItem('menuItemsFilter', JSON.stringify(updatedFilter));
    setFilteredItems(filterDataItems(updatedFilter, searchResults));
    dispatch(fetchMenuItems());
  };

  const handleTempSliderChange = (_e, newValue) => {
    setSliderValues(newValue);
  };

  const searchFieldAndButton = () => (
    <Grid
      className={classes.searchAndButtonWrapper}
      container
      item
      xs={12}
      sm={12}
      md={6}
      lg={4}
      xl={2}
      spacing={2}
    >
      <Grid item xs={12} sm={12} md={10} lg={12} className={classes.box}>
        <Box>
          <TextField
            helperText={searchError}
            className={classes.searchBar}
            id="outlined-basic"
            label="Search Stock"
            variant="outlined"
            onChange={handleSearch}
            size="small"
          />
        </Box>
        <Box className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            disabled={!isRoleAtLeastManager() || permissionReadOnly}
            to="/menu-items/add"
          >
            New Item
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  useEffect(() => {
    if (shouldLoad(menuItemsState)) dispatch(fetchMenuItems());
    setFilteredItems(filterDataItems(filters, searchResults || data));
  }, [data, dispatch, filters, menuItemsState, searchResults, setFilteredItems]);

  useEffect(() => {
    if (data) {
      const initialFormValues = newData();
      setInitialValues(initialFormValues);
    }
  }, [data, newData]);

  return (
    <>
      <PageHeader fullWidth>
        <Grid container className={classes.tableControlsBox} direction="row">
          <Grid container item xs={12} sm={12} md={12} direction="row" spacing={2}>
            {!isDesktop && searchFieldAndButton()}

            {data && data.length > 0 && filters && (
              <Grid container spacing={2} item xs={12} sm={12} md={6} lg={8} xl={10}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeCheckboxState.FOOD}
                        onChange={(_e, values) => handleFilterChange(_e, values, 'types')}
                        inputProps={{ 'aria-label': 'food' }}
                        id="food-type-checkbox"
                        name="FOOD"
                      />
                    }
                    label="FOOD"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={typeCheckboxState.DRINK}
                        onChange={(_e, values) => handleFilterChange(_e, values, 'types')}
                        inputProps={{ 'aria-label': 'drink' }}
                        id="drink-type-checkbox"
                        name="DRINK"
                      />
                    }
                    label="DRINK"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={availabilityCheckboxState.TRUE}
                        onChange={(_e, values) => handleFilterChange(_e, values, 'available')}
                        inputProps={{ 'aria-label': 'available' }}
                        id="available-checkbox"
                        name="TRUE"
                      />
                    }
                    label="AVAILABLE"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={availabilityCheckboxState.FALSE}
                        onChange={(_e, values) => handleFilterChange(_e, values, 'available')}
                        inputProps={{ 'aria-label': 'not-available' }}
                        id="not-available-checkbox"
                        name="FALSE"
                      />
                    }
                    label="
                      NOT AVAILABLE"
                  />
                </Grid>
              </Grid>
            )}

            {isDesktop && searchFieldAndButton()}
          </Grid>
          {data && data.length > 0 && filters && (
            <Grid
              className={classes.categorySliderWrapper}
              container
              item
              spacing={2}
              xs={12}
              sm={12}
              md={12}
              direction="row"
            >
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  fullWidth
                  multiple
                  limitTags={isMobile ? 6 : 5}
                  id="categories-autocomplete"
                  options={categoryItems}
                  getOptionLabel={(option) => option}
                  defaultValue={
                    sessionStorageFilters ? sessionStorageFilters[0].categories : categoryItems
                  }
                  value={
                    sessionStorageFilters ? sessionStorageFilters[0].categories : categoryItems
                  }
                  onChange={(_e, values) => handleFilterChange(_e, values, 'categories')}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Categories" />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                Prices
                <Slider
                  className={classes.slider}
                  aria-labelledby="price-values"
                  getAriaLabel={(index) => `Price ${index === 0 ? 'minimum' : 'maximum'}`}
                  getAriaValueText={(value) => `£${value}`}
                  valueLabelDisplay="on"
                  valueLabelFormat={(value) => `£${value}`}
                  step={itemPrices > 1000 ? 100 : 1}
                  marks
                  min={0}
                  max={itemPrices || 100}
                  label="Prices"
                  name="prices"
                  value={sliderValues}
                  onChange={handleTempSliderChange}
                  onChangeCommitted={(_e, newValue) =>
                    handleFilterChange(_e, newValue, 'itemPrices')
                  }
                  disabled={data && data.length < 0}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </PageHeader>
      <Page loading={loading} error={error} fullWidth>
        {filteredItems && filteredItems.length === 0 && (
          <Box style={{ padding: 14 }}>
            <Typography align="center" variant="h2" color="textSecondary">
              No menu items found
            </Typography>
          </Box>
        )}
        {data && (
          <>
            {filteredItems && filteredItems.length > 0 && (
              <Card>
                <Formik initialValues={[...newData()]}>
                  {({ setFieldValue, values, dirty, resetForm, errors, isValid }) => (
                    <>
                      <UniversalSave
                        isValid={isValid}
                        errors={errors}
                        dirty={dirty}
                        onSave={() => handleAvailableChange(values)}
                        onDiscard={resetForm}
                      />
                      <Form>
                        <FormObserver setFieldValue={setFieldValue} setValueData={setValueData} />
                        <OrderableTable
                          tableData={[...newData()]}
                          titles={[
                            'INTERNAL NAME',
                            'EXTERNAL LABEL',
                            'CATEGORY',
                            'TYPE',
                            'AVAILABLE',
                            'PRICES',
                            '',
                          ]}
                          keys={['itemId']}
                          excludeFields={['itemId', 'readonly']}
                          disableColumnTitles={['PRICES', '']}
                          values={values}
                          valueFormatter={valueFormatter}
                        />
                      </Form>
                    </>
                  )}
                </Formik>
              </Card>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default withVenue(MenuItems);
